// Skills Icons
import htmlIcon from "./images/html.svg"
import cssIcon from "./images/css.svg"
import reactIcon from "./images/react.svg"
import jsIcon from "./images/javascript.svg"
import designIcon from "./images/design.svg"
import codeIcon from "./images/code.svg"
import databaseIcon from "./images/database.png"
import cloudIcon from "./images/cloud.png"
import certificationIcon from "./images/certification.png"
import chartIcon from "./images/chart.png"

// Project Icon
import projectSixIcon from "./images/influxdb.png"

// Social Icon
import githubIcon from "./images/github.svg"
import codepenIcon from "./images/codepen.svg"
import dribbbleIcon from "./images/dribbble.svg"
import instagramIcon from "./images/icons8-instagram-144.png"
import fiverrIcon from "./images/fiverr-144.png"
import linkedinIcon from "./images/linkedin-144.png"

export default {
  //(Please Do Not Remove The comma(,) after every variable)
  //Change The Website Template

  //   Header Details ---------------------
  name: "Arwin",
  headerTagline: [
    //Line 1 For Header
    "Tutoring",
    //Line 2 For Header
    "Software Development",
    //Line 3 For Header
    "Tech Consulting"
  ],
  //   Header Paragraph
  headerParagraph:
    "I am a dedicated part-time freelancer who takes pride in quality and precision. Level 2 Seller on Fiverr with over 255 five-star reviews, having completed almost 600 orders for clients from 50+ different countries. Writer for 8 Medium publications averaging 5000+ article views per month",

  //Contact Email
  contactEmail: "arwinlashawnfreelance@gmail.com",

  // End Header Details -----------------------

  // Work Section ------------------------
  projects: [
    {
      title: "Real-Time Queue Counting Solution at IKEA JEM, Singapore", //Project Title - Add Your Project Title Here
      para:
        "Spearheaded the development of a cloud-hosted solution that helps customers optimize their waiting time.", // Add Your Service Type Here
      //Project Image - Add Your P22roject Image Here
      imageSrc:
        "https://images.unsplash.com/photo-1629921976897-b4ce6795e560?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1120&q=80",
      //Project URL - Add Your Project Url Here
      url: "https://www.ikea.com/sg/en/newsroom/corporate-news/southeast-asias-first-ikea-small-store-concept-now-open-at-jem-pubf36e1d60",
    },
    {
      title: "Freelancer on Fiverr", //Project Title - Add Your Project Title Here
      para:
        "Having amassed more than 255 five-star reviews from a myriad of Fiverr gigs, I am a Level 2 Seller who mainly do AWS, programming, math and physics tutoring now.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://images.unsplash.com/photo-1588064578354-c1e28d429246?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      //Project URL - Add Your Project Url Here
      url: "https://www.fiverr.com/arwinlashawn",
    },
    {
      title: "Technical Writing", //Project Title - Add Your Project Title Here
      para:
        "Witness the fusion of my passion for tech and writing through words.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://images.unsplash.com/photo-1566939028087-c4be299e0593?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
      //Project URL - Add Your Project Url Here
      url: "https://scoutapm.com/blog/author/arwin-lashawn",
    },
    {
      title: "Cloud Cost Optimization", //Project Title - Add Your Project Title Here
      para:
        "Optimized the company's Azure cloud cost usage, resulting in a net cost reduction of over 75%.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://images.unsplash.com/photo-1580826237584-fda5b612e1bc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=627&q=80",
      //Project URL - Add Your Project Url Here
      url: "https://azure.microsoft.com/en-us/",
    },
    {
      title: "Visualization of Text Sentiment Data", //Project Title - Add Your Project Title Here
      para:
        "Integrated AWS Comprehend, OpenSearch and Lambda to automate text sentiment analysis and craft powerful visualizations to enable intuitive sentiment analysis.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1744&q=80",
      //Project URL - Add Your Project Url Here
      url: "https://aws.amazon.com/comprehend/",
    },
    {
      title: "AWS x InfluxDB Visualization Project", //Project Title - Add Your Project Title Here
      para:
        "Combined AWS S3, Lambda, and InfluxDB technologies to achieve a near real time visualization of sensor data.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        projectSixIcon,
      //Project URL - Add Your Project Url Here
      url: "https://www.influxdata.com/",
    },

    /*
    If You Want To Add More Project just Copy and Paste This At The End
    ,{
        title: 'Project Five',
        para: 'Something Amazing',
        imageSrc: "",
        url: ''
    }
    */
  ],

  // End Work Section -----------------------

  // About Secton --------------
  aboutParaOne:
    "I graduated from the University of California, Davis in Spring of '20 with a degree in biotechnology (microbiology turned bioinformatics track). Landed a job in tech at Tapway and never looked back. I now work in Microsoft as an Azure support engineer, supporting various Fortune 500 and strategic customers globally.",
  aboutParaTwo:
    "As the Cloud and Data Lead at Tapway, I specialized in AWS, APIs, and data pipeline. I find satisfaction in developing resilient data pipelines from edge to cloud, or within cloud itself, then ultimately visualizing the data to gain actionable insights.",
  aboutParaThree:
    "I also enjoy freelancing part-time. A big part of it is getting to meet interesting individuals from all around the world. I mostly do tutoring/consulting for AWS/Azure, math, physics, and programming. I enjoy pretty much everything about tech - may build something cool one day.", 
  aboutImage:
    "https://images.unsplash.com/photo-1515879218367-8466d910aaa4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80",

  //   End About Section ---------------------

  // Skills Section ---------------

  //   Import Icons from the top and link it here

  skills: [
    // {
    //   img: htmlIcon,
    //   para:
    //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    // },
    // {
    //   img: cssIcon,
    //   para:
    //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    // },
    // {
    //   img: jsIcon,
    //   para:
    //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    // },
    // {
    //   img: reactIcon,
    //   para:
    //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    // },
    {
      img: codeIcon,
      para:
        "HTML5, CSS, JavaScript, Python, R, Bash",
    },
    {
      img: designIcon,
      para:
        "AWS Amplify, AWS Chalice, AWS SAM, React, Gatsby, Django",
    },
    {
      img: databaseIcon,
      para:
        "DynamoDB, Cosmos DB, Elasticsearch, Amplify Datastore, GraphQL, SQL DB",
    },
    {
      img: cloudIcon,
      para:
        "AWS, Azure, Huawei Cloud, DigitalOcean, InfluxDB",
    },
    {
      img: certificationIcon,
      para:
        "AWS Cloud Practitioner, Solutions Architect Associate, Developer Associate | Azure Fundamentals (AZ-900), Data Fundamentals (DP-900), AI Fundamentals (AI-900), Security Fundamentals (SC-900), DP-420, AZ-700, AZ-204, DP-203, AZ-104, AZ-500",
    },
    {
      img: chartIcon,
      para:
        "Kibana, PowerBI, Tableau",
    },
  ],

  // End Skills Section --------------------------

  //   Promotion Section --------------------------

  promotionHeading: "Heading",
  promotionPara:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  // End Promotion Section -----------------

  //   Contact Section --------------

  contactSubHeading: "Let's create something special",
  social: [
    // Add Or Remove The Link Accordingly
    {
      img: fiverrIcon,
      url: "https://www.fiverr.com/arwinlashawn",
    },
    {
      img: linkedinIcon,
      url: "https://www.linkedin.com/in/arwinlashawn/",
    },
    { img: githubIcon,
      url: "https://github.com/arwinlashawn" 
    },
    // {
    //   img: codepenIcon,
    //   url: "https://www.codepen.com/",
    // },
    // {
    //   img: dribbbleIcon,
    //   url: "https://dribbble.com/chetanverma",
    // },
    {
      img: instagramIcon,
      url: "https://www.instagram.com/arwin_lashawn",
    },
  ],

  // End Contact Section ---------------
}

// Thanks for using this template, I would love to hear from you contact me at hello@chetanverma.com
